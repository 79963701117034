<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">About Radiometric Self Calibration</font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><em>
              </em>              <div align="left"> <span class="style6">Objective</span>                
              <p>Many computer vision algorithms are based on the assumption that image brightness is proportional to scene radiance. However, an imaging system has a radiometric response function that is seldom linear. Our objective is to develop methods for computing the response function of an imaging system from multiple images of an arbitrary scene taken using the imaging system. The sensor could be a video camera, photographic camera, a digital still camera, or any other imaging system. In addressing this radiometric calibration problem, we address two sub-problems, namely, computing the response function of the complete imaging system and computing a high dynamic range radiance image from the set of captured images.<br>
                <br>
              </p>
                <p class="style6">Radiometric Self-Calibration Algorithm
                </p>
                <p> To achieve the above goals, we have developed a radiometric self-calibration algorithm. The algorithm computes the response function of the imaging system from a plurality of images of an arbitrary scene taken using different exposures. Since, in any imaging system it is difficult to obtain precise values for the exposures (for instance, the aperture setting on a lens only gives an approximate exposure value), we have developed an algorithm that takes rough exposure estimates and the corresponding images to compute the response function of the system, the precise exposure values and a single high dynamic range radiance image of the scene. The basic flow of data in our algorithm is illustrated below. Details of the algorithm are described in our CVPR99 paper. </p>
                <p align="center"> <em><a href="https://cave.cs.columbia.edu/old/software/rascal/image/figure_algorithm.gif"> <img src="https://cave.cs.columbia.edu/old/software/rascal/image/figure_algorithm.small.gif" border="0"></a> </em></p>
                <span class="style6"><br>
                Detail Brush</span>
                <p> The radiance images which are computed by our algorithm have high dynamic range (typically 12 bits or more per color channel). Hence, it is difficult to visualize these images on the usual display devices that have at most 8 bits of resolution per color channel. Therefore, we have developed an interactive tool, called the "detail brush" to view the richness of visual information embedded within the radiance image. This tool permits the user to slide a brush (window) over the image while the brush enhances the visual information within its boundaries. An on-line demonstration of the detail brush can be found in our <router-link to="/repository/RASCAL/gallery">Gallery</router-link>. <br>
                  <br>
                </p>
                <span class="style6">RASCAL (<u>RA</u>diometric <u>S</u>elf <u>CAL</u>ibration) Software</span>
                <p> We have developed a set of software tools, called RASCAL, for self-calibration and radiance recovery. The source code of our C++ implementation can be downloaded from <router-link to="/repository/RASCAL/software">RASCAL Software page</router-link>. Currently, RASCAL comprises the following application programs: </p>
                <ul>
                  <li>Three console-based programs for radiometric self-calibration,</li>
                  <li>A GUI-based program for the detail brush,</li>
                  <li>And a few of utility programs.</li>
                </ul>
                <p> The GUI-based programs in RASCAL have been implemented for MS-Windows. We have also included several <router-link to="/repository/RASCAL/software">test images</router-link> for you to experiment with. </p>
                <em><span class="style1"><br>
                </span></em></div>
            <em></em></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center"><span class="style1">              </span><em>
              <div align="left"><span class="style1">                </span></div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p>last updated :
                    <!--webbot bot="Timestamp" startspan
s-type="EDITED" s-format="%m/%d/%y" -->
                    07/29/99
                    <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </p>
                  <p>[<router-link to="/repository/RASCAL">Goto RASCAL</router-link>]<span class="style1">
                    <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </span></p>
                </div>
            </div></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>